import { AuthService } from './auth.service';
import { HelperService } from "./../shared/services/helper.service";
import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from "@angular/common/http";
import {
  catchError,
  map,
  share,
  switchMap
} from "rxjs/operators";
import { Observable, Subject, throwError } from "rxjs";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  public newTokenArrived = new Subject();

  constructor(
    private authService: AuthService,
    private helperService: HelperService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Get the auth token from the service.
    console.log('Auth Interceptor');
    const authToken = this.authService.getAuthToken();
    const authHandle = () => {
      if (authToken) {
        console.log('intercept req', req);
        return next.handle(
          req.clone({ headers: req.headers.set("x-auth", authToken) })
        );
      }
      return next.handle(req);
    };
    return authHandle().pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          return event;
        }
      }),
      // retry(2),
      catchError((requestError: any, caught: Observable<any>) => {

        if (
          requestError instanceof HttpErrorResponse &&
          requestError.status === 401
        ) {
          if (this.authService.refreshToken) {
            const newToken = this.authService.getNewToken();
            return newToken.pipe(
              share(),
              switchMap((res: { token }) => {
                this.authService.token = res.token;
                localStorage.setItem("token", res.token);
                return next.handle(
                  req.clone({ headers: req.headers.set("x-auth", res.token) })
                );
              })
            );
          } else {
            return throwError(
              "Refresh token is not available on client."
            );
          }
        } else if (
          requestError instanceof HttpErrorResponse &&
          requestError.status === 403
        ) {
          this.helperService.handleError("403");
          return throwError(requestError);
        } else {
          return throwError(requestError);
        }
      })
    );
  }
}
