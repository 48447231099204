import { apiParameters } from '../api.params';
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { HelperService } from './helper.service';

@Injectable()
export class FaqService {
  constructor(private http: HttpClient, private helperService: HelperService) {}

  getFaq() {
    return this.http.get(apiParameters.url + "faq/");
  }

  async postNewFaq(faq) {
    try {
      await this.http.post(apiParameters.url + "faq/", faq).toPromise();
    } catch (err) {
      console.log("err:", err);
    }
  }

  async updateFaq(value) {
    const httpOptions = {
      params: new HttpParams().set("_id", value._id),
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    console.log(httpOptions);
    const body = JSON.stringify(value);
    return this.http.patch(
      apiParameters.url + "faq/",
      body,
      httpOptions
    ).toPromise();
  }


  getFaqById(_id) {
    const httpOptions = {
      params: new HttpParams().set("_id", _id)
    };
    return this.http.get(apiParameters.url + "question/", httpOptions);
  }


  async deleteFaq(_id) {
    const httpOptions = {
      params: new HttpParams().set("_id", _id)
    };
    console.log(httpOptions);
    try {
      await this.http
        .delete(apiParameters.url + "faq", httpOptions)
        .toPromise();
    } catch (err) {
      console.log("Delete Faq, ERROR:", err);
    }
  }
}
