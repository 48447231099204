import { Router } from '@angular/router';
import { AuthService } from './../auth/auth.service';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { HelperService } from '../shared/services/helper.service';

@Component({
  selector: "page-login",
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loginFormSubmitted = false;
  loginErrUsername = "";
  loginErrPassword = "";
  loggedIn = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private helperService: HelperService
  ) {}

ngOnInit() {
  this.loggedIn = this.helperService.isLoggedIn();
  if (!this.loggedIn) {
    this.loginForm = this.formBuilder.group({
      username: [
        null,
        Validators.compose([
          Validators.required
        ])
      ],
      password: [null, Validators.compose([Validators.required])]
    });
  }
  }

  login() {//admin password LovefestAdmin2019
    this.loginFormSubmitted = true;
    console.log("LOGIN FORM: ", this.loginForm);
    if (this.loginForm.valid) {
      this.authService
        .signInAdmin({
          username: this.loginForm.controls.username.value,
          password: this.loginForm.controls.password.value
        })
        .subscribe(
          (res: { user; token; refreshToken }) => {
            this.authService.token = res.token;
            this.authService.refreshToken = res.refreshToken;
            localStorage.setItem("token", res.token);
            localStorage.setItem("refreshToken", res.refreshToken);
            this.router.navigate(["events"]);
          },
          err => {
            console.log("LOGIN - error: ", err);
            switch (err.error) {
              case "password-incorrect":
                this.loginErrPassword = "Password is incorrect.";
                break;
              case "username-unknown":
                this.loginErrUsername =
                  "Username is incorrect.";
                break;
            }
          }
        );
    }
  }


  logout() {
    this.authService.logoutAdmin().subscribe(() => {
      this.authService.token = null;
      this.authService.refreshToken = null;
      localStorage.setItem("token", null);
      localStorage.setItem("refreshToken", null);
      this.router.navigate(["/events"]);
    });
  }

  deleteErrorMessage(element) {
    switch (element) {
      case "login-username":
        if (this.loginErrUsername) {
          this.loginErrUsername = "";
        break;
        }
      case "login-password":
        if (this.loginErrPassword) {
          this.loginErrPassword = "";
        break;
        }
    }
  }

  cancel() {
    this.router.navigate(["/"]);
  }

}
