import { apiParameters } from './../api.params';
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { HelperService } from './helper.service';

@Injectable()
export class MapsService {
  constructor(private http: HttpClient, private helperService: HelperService) {}

  getMaps() {
    return this.http.get(apiParameters.url + "maps/");
  }

  async postNewMap(map, fileImage) {
    try {
      const image = await this.helperService.uploadToS3(fileImage, "images/maps/");
      map.img = image.Location;
      await this.http.post(apiParameters.url + "maps/", map).toPromise();
    } catch (err) {
      console.log("err:", err);
    }
  }

  async updateMap(fileImage, value) {
    if (fileImage) {
      try {
        await this.helperService.deleteFromS3(value.img, "images/maps/");
        const file = await this.helperService.uploadToS3(
          fileImage,
          "images/maps/"
        );
        value.img = file.Location;
      } catch (err) {
        console.log(err);
      }
    }
    const httpOptions = {
      params: new HttpParams().set("_id", value._id),
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    const body = JSON.stringify(value);
    return this.http.patch(
      apiParameters.url + "maps/",
      body,
      httpOptions
    ).toPromise();
  }


  getMapById(_id) {
    const httpOptions = {
      params: new HttpParams().set("_id", _id)
    };
    return this.http.get(apiParameters.url + "map", httpOptions);
  }


  async deleteMap(map) {
    const httpOptions = {
      params: new HttpParams().set('_id', map._id)
    };
    try {
      await this.helperService.deleteFromS3(map.img, "images/maps/");
      await this.http
        .delete(apiParameters.url + "maps", httpOptions)
        .toPromise();
    } catch (err) {
      console.log("Delete map, ERROR:", err);
    }
  }
}
