import { apiParameters } from './../shared/api.params';
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

@Injectable()
export class AuthService {
  token: string = null;
  refreshToken: string = null;


  constructor(private http: HttpClient) {
    if (!this.token) {
      this.token = localStorage.getItem("token");
      this.refreshToken = localStorage.getItem("refreshToken");
    }
  }

  signInAdmin(userData) {
    return this.http.post(apiParameters.url + "admin/signin", userData);
  }

  logoutAdmin() {
    return this.http.delete(apiParameters.url + "users/signout");
  }

  getNewToken() {
      let headers = new HttpHeaders();
      headers = headers.append( "x-refresh-token", this.refreshToken);
    return this.http.post(apiParameters.url + "users/token", {headers: headers});
  }

  isAuthenticated() {
    return this.token;
  }

  getAuthToken() {
    return this.token;
  }

  getUser() {
    return this.http.get(apiParameters.url + "users/me");
  }
}
