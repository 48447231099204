import { HelperService } from "./helper.service";
import { IMerchItem } from "./../interfaces/merchItem";
import { apiParameters } from "./../api.params";
import { ITicket } from "./../interfaces/ticket.interface";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Subject, Observable } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable()
export class MerchService {
  items: IMerchItem[] = [];

  constructor(private http: HttpClient, private helperService: HelperService) {}

  async saveNewItem(item, itemImages) {
    try {
      for (let index = 0; index < itemImages.length; index++) {
        const file = await this.helperService.uploadToS3(
          itemImages[index],
          "images/merch/"
        );
        for (let index = 0; index < item.images.length; index++) {
          if (
            item.images[index].load &&
            file.Key.indexOf(item.images[index].name) > -1
          ) {
            item.images[index] = file.Location;
          }
        }
      }
      await this.http.post(apiParameters.url + "merch/", item).toPromise();
    } catch (err) {
      console.log("Post New Merch Item, ERROR:", err);
    }
  }

  getItemById(_id) {
    const httpOptions = {
      params: new HttpParams().set("_id", _id)
    };
    return this.http.get(apiParameters.url + "merch-item/", httpOptions);
  }

  getItems(type: string): Observable<IMerchItem[]> {
    const httpOptions = {
      params: new HttpParams().set("kind", type)
    };
    return <Observable<IMerchItem[]>>(
      this.http.get(apiParameters.url + "merch-item/", httpOptions)
    );
  }

  async updateItem(value, deletedImages, newImages) {
    try {
      for (let index = 0; index < deletedImages.length; index++) {
        await this.helperService.deleteFromS3(
          deletedImages[index],
          "images/merch/"
        );
      }
      for (let index = 0; index < newImages.length; index++) {
        const file = await this.helperService.uploadToS3(
          newImages[index],
          "images/merch/"
        );
        for (let index = 0; index < value.images.length; index++) {
          if (
            value.images[index].load &&
            file.Key.indexOf(value.images[index].name) > -1
          ) {
            value.images[index] = file.Location;
          }
        }
      }
      const httpOptions = {
        params: new HttpParams().set("_id", value._id),
        headers: new HttpHeaders({
          "Content-Type": "application/json"
        })
      };
      const body = JSON.stringify(value);
      return this.http
        .patch(apiParameters.url + "merch/", body, httpOptions)
        .toPromise();
    } catch (err) {
      console.log("Delete Merch Item, ERROR:", err);
    }
  }

  async deleteItem(item: IMerchItem) {
    const httpOptions = {
      params: new HttpParams().set("_id", item._id)
    };
    try {
      for (let index = 0; index < item.images.length; index++) {
        await this.helperService.deleteFromS3(
          item.images[index],
          "images/merch/"
        );
      }
      await this.http
        .delete(apiParameters.url + "merch", httpOptions)
        .toPromise();
    } catch (err) {
      console.log("Delete Merch Item, ERROR:", err);
    }
  }
}
