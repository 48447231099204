import { HelperService } from "./shared/services/helper.service";
import { AuthService } from "./auth/auth.service";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  activeTab = null;

  constructor(private helperService: HelperService) {}

  isLoggedIn() {
    return this.helperService.isLoggedIn();
  }
}
