import { HelperService } from "./helper.service";
import { apiParameters } from "../api.params";
import { IEvent} from "../interfaces/event.interface";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable()
export class EventsService {
  events: IEvent[];

  constructor(private http: HttpClient, private helperService: HelperService) {}

  async postNewEvent(event, fileImage) {
    try {
      const file = await this.helperService.uploadToS3(
        fileImage,
        "images/events/"
      );
      event.img = file.Location;
      await this.http
        .post(apiParameters.url + "events/" + event.type, event)
        .toPromise();
    } catch (err) {
      console.log("Post New Event, ERROR:", err);
    }
  }

  async updateEvent(fileImage, value) {
    const kind = value.kind === "SmallEvent" ? "small" : "fest";
    if (fileImage) {
      try {
        await this.helperService.deleteFromS3(value.img, "images/events/");
        const file = await this.helperService.uploadToS3(
          fileImage,
          "images/events/"
        );
        value.img = file.Location;
      } catch (err) {
        console.log(err);
      }
    }
    const httpOptions = {
      params: new HttpParams().set("_id", value._id),
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    const body = JSON.stringify(value);
    return this.http.patch(
      apiParameters.url + "events/" + kind,
      body,
      httpOptions
    ).toPromise();
  }

  getEvents(): Observable<IEvent[]> {
    return <Observable<IEvent[]>>this.http.get(apiParameters.url + "events");
  }

  getSmallEvents(): Observable<IEvent[]> {
    return <Observable<IEvent[]>>(
      this.http.get(apiParameters.url + "events/small")
    );
  }

  getFestEvents(): Observable<IEvent[]> {
    return <Observable<IEvent[]>>(
      this.http.get(apiParameters.url + "events/fest")
    );
  }

  getEventById(_id) {
    const httpOptions = {
      params: new HttpParams().set("_id", _id)
    };
    return this.http.get(apiParameters.url + "event", httpOptions);
  }

  async deleteEvent(event: IEvent) {
    const httpOptions = {
      params: new HttpParams().set("_id", event._id)
    };
    try {
      await this.helperService.deleteFromS3(event.img, "images/events/");
      await this.http
        .delete(apiParameters.url + "events", httpOptions)
        .toPromise();
    } catch (err) {
      console.log("Delete Event, ERROR:", err);
    }
  }
}
