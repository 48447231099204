import { AuthGuard } from './auth/auth.guard';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { NgModule} from '@angular/core';
import { Routes, RouterModule, PreloadAllModules} from '@angular/router';
import { OrdersComponent } from './orders/orders.component';

const routes: Routes = [
   {path: '', canActivate: [AuthGuard], component: HomeComponent, pathMatch: 'full'},
   {path: 'home', redirectTo: ''},
   {path: 'login', component: LoginComponent },
   {path: 'logout', component: LoginComponent },
   {path: 'events', loadChildren: './events/events.module#EventsModule'},
   {path: 'artists', loadChildren: './artists/artists.module#ArtistsModule'},
  {path: 'tickets', loadChildren: './tickets/tickets.module#TicketsModule'},
  {path: 'timetable', loadChildren: './timetable/timetable.module#TimetableModule'},
  {path: 'merch', loadChildren: './merch/merch.module#MerchModule'},
  {path: 'maps', loadChildren: './maps/maps.module#MapsModule'},
  {path: 'faq', loadChildren: './faq/faq.module#FaqModule'},
  {path: 'orders', component: OrdersComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})], // preload all modules before are used
  exports: [RouterModule]
})
export class AppRoutingModule { }
