import { IArtist } from "./../interfaces/artist.interface";
import { apiParameters } from "./../api.params";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Subject, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HelperService } from "./helper.service";

@Injectable()
export class ArtistsService {
  artists: any[] = [];

  constructor(private http: HttpClient, private helperService: HelperService) {}
  // getArtistsForDate(festDay,festEvent=this.festEvent) {

  //   return this.artists.filter(artist => {
  //     return artist.events.find(
  //       event => {
  //         return festEvent.name === event["e-name"] &&
  //         new Date(event.date).toDateString() === festDay.toDateString();}
  //     );
  //   });
  // }

  getArtists(): Observable<IArtist[]> {
    return <Observable<IArtist[]>>this.http.get(apiParameters.url + "artists");
  }

  getArtistById(_id) {
    const httpOptions = {
      params: new HttpParams().set("_id", _id)
    };
    return this.http.get(apiParameters.url + "artist", httpOptions);
  }

  async postNewArtist(artist, fileImage, fileAudio) {
    let audio;
    let image;
    try {
      image = await this.helperService.uploadToS3(fileImage, "images/artists/");
      artist.img = image.Location;
      if (fileAudio !== null) {
        audio = await this.helperService.uploadToS3(
          fileAudio,
          "audio/artists/"
        );
        artist.audio = audio.Location;
      }
      await this.http.post(apiParameters.url + "artists/", artist).toPromise();
    } catch (err) {
      console.log("err:", err);
    }
  }

  async deleteArtist(artist: IArtist) {
    const httpOptions = {
      params: new HttpParams().set("_id", artist._id)
    };
    try {
      await this.helperService.deleteFromS3(artist.img, "images/artists/");
      await this.http
        .delete(apiParameters.url + "artists", httpOptions)
        .toPromise();
    } catch (err) {
      console.log("err:", err);
    }
  }

  async updateArtist(files, value) {
    let audio;
    let image;
    try {
      if (files.image) {
        await this.helperService.deleteFromS3(value.img, "images/artists/");
        image = await this.helperService.uploadToS3(
          files.image,
          "images/artists/"
        );
        value.img = image.Location;
      }
      if (files.audio !== null) {
        await this.helperService.deleteFromS3(value.img, "audio/artists/");
        audio = await this.helperService.uploadToS3(
          files.audio,
          "audio/artists/"
        );
        value.audio = audio.Location;
      }
    } catch (err) {
      console.log(err);
    }
    const httpOptions = {
      params: new HttpParams().set("_id", value._id),
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    const body = JSON.stringify(value);
    return this.http
      .patch(apiParameters.url + "artists/", body, httpOptions)
      .toPromise();
  }
}
