import { apiParameters } from "../api.params";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class ConfigurationService {
  constructor(private http: HttpClient) {}

  getTimetableData(): Observable<any> {
    return this.http.get(apiParameters.url + "timetable-config/");
  }

  getTicketsConfigData(): Observable<any> {
    return this.http.get(apiParameters.url + "tickets-config/");
  }

  getMerchConfigData(): Observable<any> {
    return this.http.get(apiParameters.url + "merch-config/");
  }

  updateTimetable(timetable, value) {
    const httpOptions = {
      params: new HttpParams().set('_id', timetable._id),
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const body = JSON.stringify(value);
    return this.http
      .patch(apiParameters.url + "timetable-config/", body, httpOptions);
  }

  saveTimetableData(data) {
    return this.http.post(apiParameters.url + "timetable-config/", data);
  }

  saveTicketsConfigData(data) {
    return this.http.post(apiParameters.url + "tickets-config/", data);
  }


  saveMerchConfigData(data) {
    return this.http.post(apiParameters.url + "merch-config/", data);
  }

  updateTicketsConfigData(ticketsConfig, value) {
    const httpOptions = {
      params: new HttpParams().set('_id', ticketsConfig._id),
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const body = JSON.stringify(value);
    return this.http
      .patch(apiParameters.url + "tickets-config/", body, httpOptions);
  }

  updateMerchConfigData(merchConfig, value) {
    const httpOptions = {
      params: new HttpParams().set('_id', merchConfig._id),
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const body = JSON.stringify(value);
    return this.http
      .patch(apiParameters.url + "merch-config/", body, httpOptions);
  }
}
