import { apiParameters } from './../api.params';
import { ITicket } from './../interfaces/ticket.interface';
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Subject } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable()
export class TicketsService {
  tickets: ITicket[] = [];

  constructor(private http: HttpClient) {}

  getTickets() {
   return this.http.get(apiParameters.url + "tickets/");
  }

  saveNewTicket(ticket) {
    return this.http.post(apiParameters.url + "tickets/" + ticket.category, ticket);
  }

  getTicketById(_id) {
    const httpOptions = {
      params: new HttpParams().set("_id", _id)
    };
    return this.http.get(apiParameters.url + "ticket", httpOptions);
  }

  deleteTicket(_id) {
    const httpOptions = {
      params: new HttpParams().set("_id", _id)
    };
   return this.http
        .delete(apiParameters.url + "tickets", httpOptions)
  }
}

