import { FaqService } from 'src/app/shared/services/faq.service';
import { OrdersModule } from './orders/orders.module';
import { MapsService } from './shared/services/maps.service';
import { MerchService } from './shared/services/merch.service';
import { HelperService } from "./shared/services/helper.service";
import { httpInterceptorProviders } from "./auth/index";
import { LoginModule } from "./login/login.module";
import { ConfigurationService } from "./shared/services/configuration.service";
import { ArtistsService } from "./shared/services/artists.service";
import { EventsService } from "./shared/services/events.service";
import { TicketsService } from "./shared/services/tickets.service";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./home/home.component";
import { HttpClientModule } from "@angular/common/http";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { AuthService } from "./auth/auth.service";
import { AuthGuard } from "./auth/auth.guard";

@NgModule({
  declarations: [AppComponent, HomeComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    LoginModule,
    OrdersModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production
    })
  ],
  providers: [
    TicketsService,
    EventsService,
    ArtistsService,
    ConfigurationService,
    AuthService,
    AuthGuard,
    MerchService,
    httpInterceptorProviders,
    HelperService,
    MapsService,
    FaqService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
