import { Router } from "@angular/router";
import { AuthService } from "./../../auth/auth.service";
import { Injectable } from "@angular/core";
import * as S3 from "aws-sdk/clients/s3";

@Injectable()
export class HelperService {
  bucket = new S3({
    region: "eu-central-1",
    accessKeyId: "AKIAIYIKXLE4AQJEYFVQ",
    secretAccessKey: "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"
  });

  constructor(private authService: AuthService, private router: Router) {}

  getWeekDay(day: number) {
    let week = [
      "MONDAY",
      "THUESDAY",
      "WEDNESDAY",
      "THURSDAY",
      "FRIDAY",
      "SATURDAY",
      "SUNDAY"
    ];
    return week[day];
  }

  getMonthWord(month: number) {
    let months = [
      "JAN.",
      "FEB.",
      "MAR.",
      "APR.",
      "MAY",
      "JUNE",
      "JULY",
      "AUG.",
      "SEP.",
      "OCT.",
      "NOV.",
      "DEC."
    ];
    return months[month];
  }

  transformDateOnly(date) {
    return (
      this.getMonthWord(date.getMonth()) + " " + date.getDate()
      // " " +
      // this.getWeekDay(date.getDay()).slice(0, 3)
    );
  }

  transformDate(dateInput) {
    const date = new Date(dateInput);
    return (
      this.getWeekDay(date.getDay()) +
      ", " +
      this.getMonthWord(date.getMonth()) +
      " " +
      date.getDate()
    );
  }

  uploadToS3(file: any, folder: string) {
    const bucketName = "lovefest-serbia";
    const type = this.getContentType(file.name);

    return this.bucket
      .upload({
        Key: folder + file.name,
        Bucket: bucketName,
        Body: file,
        ContentType: type
      })
      .promise();
  }

  deleteFromS3(file, folder) {
    const bucketName = "lovefest-serbia";
    const index = file.lastIndexOf("/") + 1;
    const img = file.substring(index);
    const params = {
      Bucket: bucketName,
      Key: folder + img
    };

    return this.bucket.deleteObject(params).promise();
  }

  isLoggedIn() {
    const token = localStorage.getItem("token");
    return token + "" !== "null";
  }

  getContentType(file) {
    const extn = file.split(".").pop();
    let contentType = "application/octet-stream";

    switch (extn) {
      case "jpg":
      case "jpeg":
        contentType = "image/jpeg";
        break;
      case "png":
        contentType = "image/png";
        break;
      case "mp3":
        contentType = "audio/mpeg";
        break;
      default:
        contentType = "application/octet-stream";
    }
    return contentType;
  }

  handleError(err: string) {
    switch (err) {
      case "403": {
        this.authService.token = null;
        this.authService.refreshToken = null;

        localStorage.set("token", null);
        localStorage.set("refreshToken", null);

        this.router.navigate(["/login"]);
      }
    }
  }
}
